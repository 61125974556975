import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../context/auth"
// import { convertToBgImage } from "gbimage-bridge"
// import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
// import SEO from "../components/seo"
// import BackgroundImage from "gatsby-background-image"
import "../css/background-image.css"
import Resizer from "react-image-file-resizer"
// import axios from "axios"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import {
  Modal,
  Tabs,
  Tab,
  Button,
  CssBaseline,
  TextField,
  Typography,
  makeStyles,
  Tooltip,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  Switch,
  Hidden,
  Link,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { injectIntl } from "gatsby-plugin-intl"
import firebase from "gatsby-plugin-firebase"
// import { graphql } from "gatsby"

const useStyles = makeStyles(theme => ({
  logo: {
    width: 50,
  },
  bandera: {
    width: 20,
  },
  paper: {
    position: "absolute",
    width: "80%",
    left: "10%",
    top: "5%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))
// si no es un usuario de los autorizados lo patee al inicio para que no lea datos o algo asi
//ademas poner que no vea renderizado

// un handleChangeExpositorSwitch expositor a true or false se grabe en el handle con un switch puedan habilitar o no

const AdministradorDeReuniones = ({ intl, reunion }) => {
  const { user } = useContext(AuthContext)
  const classes = useStyles()
  const currentLocale = intl.locale
  const [botonElegidoAdministracion, setBotonElegidoAdministracion] =
    useState("Todas")
  const [reunionesTodas, setReunionesTodas] = useState([])
  const [participantesRonda, setparticipantesRonda] = useState([])
  const handleChangeTabsAdministracion = (event, newValue) => {
    setBotonElegidoAdministracion(newValue)
  }
  useEffect(() => {
    if (user != null) {
      /****************
       * CARGA DOCUMENTO DE TODAS LAS participantes INICIO
       ****************/
      var unsuscribe = firebase
        .firestore()
        .collection("rondadenegocios")
        .onSnapshot(snapshot => {
          let participantes = []
          snapshot.forEach(doc => {
            participantes.push(
              doc.data()
              /*               {
              ciudad: doc.data().ciudad,
              codpais: doc.data().codpais,
              demandas: doc.data().demandas,
              email: doc.data().email,
              empresa: doc.data().empresa,
              expositor: doc.data().expositor,
              horas: doc.data().horas,
              logo: doc.data().logo,
              ofertas: doc.data().ofertas,
              pais: doc.data().pais,
              ramo: doc.data().ramo,
              telefono: doc.data().telefono,
              uid: doc.data().uid,
            } */
            )
          })
          setparticipantesRonda(participantes)
        })
      return function cleanup() {
        unsuscribe()
      }
      /****************
       * CARGA DOCUMENTO DE TODAS LAS participantes FIN
       ****************/
    }
  }, [user])
  useEffect(() => {
    if (user != null) {
      /****************
       * CARGA DOCUMENTO DE TODAS LAS REUNIONES INICIO
       ****************/
      var unsuscribe = firebase
        .firestore()
        .collection("reunionesronda")
        .onSnapshot(snapshot => {
          let reuniones = []
          snapshot.forEach(doc => {
            reuniones.push({
              doc_id: doc.id,
              demandante_email: doc.data().demandante_email,
              demandante_ofertas: doc.data().demandante_ofertas,
              demandante_telefono: doc.data().demandante_telefono,
              demandante_demandas: doc.data().demandante_demandas,
              demandante_empresa: doc.data().demandante_empresa,
              demandante_pais: doc.data().demandante_pais,
              demandante_codpais: doc.data().demandante_codpais,
              demandante_ciudad: doc.data().demandante_ciudad,
              demandante_ramo: doc.data().demandante_ramo,
              demandante_logo: doc.data().demandante_logo,
              demandante_uid: doc.data().demandante_uid,
              demandante_estado: doc.data().demandante_estado,
              oferente_email: doc.data().oferente_email,
              oferente_ofertas: doc.data().oferente_ofertas,
              oferente_telefono: doc.data().oferente_telefono,
              oferente_demandas: doc.data().oferente_demandas,
              oferente_empresa: doc.data().oferente_empresa,
              oferente_pais: doc.data().oferente_pais,
              oferente_codpais: doc.data().oferente_codpais,
              oferente_ciudad: doc.data().oferente_ciudad,
              oferente_ramo: doc.data().oferente_ramo,
              oferente_logo: doc.data().oferente_logo,
              oferente_uid: doc.data().oferente_uid,
              oferente_estado: doc.data().oferente_estado,

              fecha_solicitud: doc.data().fecha_solicitud,
            })
          })
          setReunionesTodas(reuniones)
        })
      return function cleanup() {
        unsuscribe()
      }
      /****************
       * CARGA DOCUMENTO DE TODAS LAS REUNIONES FIN
       ****************/
    }
  }, [user])
  const handleChangeRondaHabilitada = event => {
    //  setPaisBusqueda(event.target.checked)
    /*   axios.post(modal_ronda_registro.link_planilla, { // ACA SEGUIR
    email: data.email,
    ofertas: data.ofertas,
    telefono: data.telefono,
    demandas: data.demandas,
    empresa: data.empresa,
    pais: pais,
    ciudad: data.ciudad,
    rubro: ramo,
  }) */
  }
  const [openBorrarReunion, setOpenBorrarReunion] = useState(false) // PASAR A FALSE SI NO QUIERO ABRA AUTOMATICAMENTE
  const borrarReunion = reunionaborrar => {
    // console.log("id", reunionaborrar)
    firebase
      .firestore()
      .collection("reunionesronda")
      .doc(reunionaborrar)
      .delete()
      .then(() => {
        //     console.log("Document successfully deleted!")
        setOpenBorrarReunion(false)
        alert("Reunion eliminada")
      })
      .catch(error => {
        //   console.error("Error removing document: ", error)
        setOpenBorrarReunion(false)
        alert("Falla al eliminar reunion")
      })
  }

  const TemplateReuniones = props => {
    const lista = props.listado
    return (
      <Grid container spacing={1}>
        {lista.map(reunion => (
          <Grid item xs={12} sm={12} key={reunion.id}>
            <Card variant="outlined">
              <CardContent>
                <Grid container>
                  <Grid item xs={2}>
                    <CardMedia
                      className={classes.logo}
                      // image={`${row.logo}`}
                      component="img"
                      src={`${reunion.oferente_logo}`}
                      title={reunion.oferente_empresa}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={11}>
                        <Typography noWrap variant="h6" component="h6">
                          Oferente: {reunion.oferente_empresa}
                        </Typography>
                        {/*                      {reunion.oferente_uid === user.uid &&
                        reunion.oferente_estado === "pendiente" ? (
                          <ModalRondaElegirHorario reunion={reunion} />
                        ) : (
                          <ModalRondaModificarEstadoReunion reunion={reunion} />
                        )} */}
                      </Grid>
                      <Grid item xs={1}>
                        <CardMedia
                          className={classes.bandera}
                          // image={`${row.logo}`}
                          component="img"
                          src={`https://flagcdn.com/${reunion.oferente_codpais}.svg`}
                          //width="240"
                          title={reunion.oferente_pais}
                          // style={{width: "100%"}}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <CardMedia // ver si uso gatsbyImage o img y puedo acomodar
                          className={classes.bandera}
                          // image={`${row.logo}`}
                          component="img"
                          src={`https://flagcdn.com/${reunion.demandante_codpais}.svg`}
                          // width="24"
                          title={reunion.demandante_pais}
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography noWrap variant="h6" component="h6">
                          Solicitada por : {reunion.demandante_empresa}
                        </Typography>
                      </Grid>

                      <Hidden smUp>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            component="p"
                            gutterBottom
                          >
                            <span style={{ fontStyle: "italic", fontSize: 12 }}>
                              {reunion.oferente_ramo}
                            </span>
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="body2" component="p">
                            <span style={{ fontWeight: "bold" }}>
                              Ofertas:{" "}
                            </span>
                            <span style={{ fontStyle: "italic" }}>
                              {reunion.oferente_ofertas}
                            </span>
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            component="p"
                            gutterBottom
                          >
                            <span style={{ fontWeight: "bold" }}>
                              Demandas:{" "}
                            </span>
                            <span style={{ fontStyle: "italic" }}>
                              {reunion.oferente_demandas}
                            </span>
                          </Typography>
                        </Grid>
                      </Hidden>
                      <Hidden xsDown>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            component="p"
                            gutterBottom
                          >
                            <span style={{ fontStyle: "italic", fontSize: 12 }}>
                              {reunion.oferente_ramo}
                            </span>
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography noWrap variant="body2" component="p">
                            <span style={{ fontWeight: "bold" }}>
                              Ofertas:{" "}
                            </span>
                            <span style={{ fontStyle: "italic" }}>
                              {reunion.oferente_ofertas}
                            </span>
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            noWrap
                            variant="body2"
                            component="p"
                            gutterBottom
                          >
                            <span style={{ fontWeight: "bold" }}>
                              Demandas:{" "}
                            </span>
                            <span style={{ fontStyle: "italic" }}>
                              {reunion.oferente_demandas}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            noWrap
                            variant="body2"
                            component="p"
                            gutterBottom
                          >
                            <span style={{ fontWeight: "bold" }}>
                              Estado oferente:{" "}
                            </span>
                            <span style={{ fontStyle: "italic" }}>
                              {reunion.oferente_estado}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            noWrap
                            variant="body2"
                            component="p"
                            gutterBottom
                          >
                            <span style={{ fontWeight: "bold" }}>
                              Estado demandante:{" "}
                            </span>
                            <span style={{ fontStyle: "italic" }}>
                              {reunion.demandante_estado}
                            </span>
                          </Typography>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Grid>
                  <button
                    type="button"
                    onClick={() => setOpenBorrarReunion(true)}
                  >
                    <DeleteForeverIcon fontSize="small" />
                  </button>
                  <Modal
                    open={openBorrarReunion}
                    onClose={() => setOpenBorrarReunion(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <div className={classes.paper}>
                      <h2 id="simple-modal-title">Eliminar reunión</h2>
                      <p id="simple-modal-description">
                        Esta seguro de borrar esta reunion? esta acción no es
                        reversible.
                      </p>

                      <button
                        type="button"
                        onClick={() => borrarReunion(reunion.doc_id)}
                      >
                        <DeleteForeverIcon fontSize="small" /> Borrar
                      </button>
                    </div>
                  </Modal>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    )
  }

  const Aceptadas = () => {
    // MUESTRA LAS ACEPTADAS POR AMBOS
    var aceptadas = reunionesTodas.filter(function (el) {
      return (
        el.demandante_estado === "aceptada" && el.oferente_estado === "aceptada"
      )
    })
    return <TemplateReuniones listado={aceptadas} />
  }
  const Pendientes = () => {
    // MUESTRA LAS PENDIENTES POR ALGUNO DE AMBOS
    var pendientes = reunionesTodas.filter(function (el) {
      return (
        (el.demandante_estado === "pendiente" &&
          el.oferente_estado !== "cancelada") ||
        (el.oferente_estado === "pendiente" &&
          el.demandante_estado !== "cancelada")
      )
    })
    return <TemplateReuniones listado={pendientes} />
  }
  const Canceladas = () => {
    // MUESTRA LAS canceladas POR ALGUNO DE AMBOS
    var canceladas = reunionesTodas.filter(function (el) {
      return (
        el.demandante_estado === "cancelada" ||
        el.oferente_estado === "cancelada"
      )
    })
    return <TemplateReuniones listado={canceladas} />
  }
  const Todas = () => {
    return <TemplateReuniones listado={reunionesTodas} />
  }
  const CrearReunion = () => {
    return ""
  }
  const Participantes = () => {
    const [empresaBusqueda, setEmpresaBusqueda] = useState(true)
    const [ofertasBusqueda, setOfertasBusqueda] = useState(false)
    const [demandasBusqueda, setDemandasBusqueda] = useState(false)
    const [paisBusqueda, setPaisBusqueda] = useState(false)
    const [openBorrarParticipante, setOpenBorrarParticipante] = useState(false) // PASAR A FALSE SI NO QUIERO ABRA AUTOMATICAMENTE
    const borrarParticipante = participanteaborrar => {
      // console.log("id", reunionaborrar)
      firebase
        .firestore()
        .collection("rondadenegocios")
        .doc(participanteaborrar)
        .delete()
        .then(() => {
          //     console.log("Document successfully deleted!")
          setOpenBorrarParticipante(false)
          alert("Participante eliminado")
        })
        .catch(error => {
          //   console.error("Error removing document: ", error)
          setOpenBorrarParticipante(false)
          alert("Falla al eliminar participante")
        })
    }

    const [listadoFiltrado, setListadoFiltrado] = useState([{}])
    const [search, setSearch] = useState("")
    useEffect(() => {
      filtrado()
    }, [search])
    const handleChangeEmpresaBusqueda = event => {
      setEmpresaBusqueda(event.target.checked)
    }
    const handleChangeOfertasBusqueda = event => {
      setOfertasBusqueda(event.target.checked)
    }
    const handleChangeDemandasBusqueda = event => {
      setDemandasBusqueda(event.target.checked)
    }
    const handleChangePaisBusqueda = event => {
      setPaisBusqueda(event.target.checked)
    }
    const handleChangeExpositorSwitch = event => {
      //  console.log("event name", event.target.name)
      firebase
        .firestore()
        .collection("rondadenegocios")
        .doc(event.target.name)
        .update({
          expositor: event.target.checked,
        })
      //   setState({ ...state, [event.target.name]: event.target.checked });
    }
    const handleChangeParticipanteHabilitado = event => {
      //  console.log("event name", event.target.name)
      firebase
        .firestore()
        .collection("rondadenegocios")
        .doc(event.target.name)
        .update({
          habilitado: event.target.checked,
        })
      //   setState({ ...state, [event.target.name]: event.target.checked });
    }
    const filtrado = () => {
      /*     setListadoFiltrado(
        rows.filter(row =>
          row.empresa.toLowerCase().includes(search.toLowerCase())
        )
      ) */
      var resultadoFiltrado = []
      participantesRonda.filter(function (row) {
        if (empresaBusqueda) {
          if (row.empresa.toLowerCase().includes(search.toLowerCase())) {
            resultadoFiltrado.push(row)
            return
          }
        }
        if (ofertasBusqueda) {
          if (row.ofertas.toLowerCase().includes(search.toLowerCase())) {
            resultadoFiltrado.push(row)
            return
          }
        }
        if (demandasBusqueda) {
          if (row.demandas.toLowerCase().includes(search.toLowerCase())) {
            resultadoFiltrado.push(row)
            return
          }
        }
        if (paisBusqueda) {
          if (row.pais.toLowerCase().includes(search.toLowerCase())) {
            resultadoFiltrado.push(row)
            return
          }
        }
      })
      setListadoFiltrado(resultadoFiltrado)
      /*     setListadoFiltrado(
        rows.filter(function (row) {
          if (empresaBusqueda) {
            row.empresa.toLowerCase().includes(search.toLowerCase())
            return row
          }
          if (empresaBusqueda) {
            row.ofertas.toLowerCase().includes(search.toLowerCase())
          }
        })
      ) */
    }

    return (
      <>
        <div
          style={{
            width: "80%",
            position: "absolute",
            //  height: "100%",
            top: "1%",
            left: 0,
          }}
        >
          <Grid container spacing={0}>
            {listadoFiltrado.map(row => (
              <Grid item xs={12} sm={12} key={row.id}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container>
                      <Grid item xs={2}>
                        <div
                          style={{
                            //  margin: 0,
                            margin: "auto",
                            //  textAlign: "center",
                            //  alignItems: "center",
                            //maxHeight: `5vw`,
                            // height: "50%",
                            //   width: "70%",
                          }}
                        >
                          <CardMedia
                            className={classes.logo}
                            // image={`${row.logo}`}
                            component="img"
                            src={`${row.logo}`}
                            title={row.empresa}
                            style={{ margin: 0 }} //CAMBIO 140721
                          />
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <Grid container>
                          <Grid item xs={8}>
                            <div style={{ fontSize: 20 }}>
                              {row.empresa}
                              <span
                                style={{ fontStyle: "italic", fontSize: 12 }}
                              >
                                {"  -  "} {row.ramo}
                              </span>
                            </div>
                          </Grid>
                          <Grid item xs={4} container justify="flex-end">
                            {/*                           <ModalReunirmeRonda
                              row={row}
                              docRonda={docRonda}
                              // fechas={fechas}
                              reunionesTodas={reunionesTodas}
                              modal_reunirme_ronda={modal_reunirme_ronda}
                            /> */}
                          </Grid>
                          <Grid item xs={1}>
                            <CardMedia
                              className={classes.bandera}
                              // image={`${row.logo}`}
                              component="img"
                              src={`https://flagcdn.com/${row.codpais}.svg`}
                              // width="24"
                              title={row.pais}
                            />
                          </Grid>
                          <Hidden smUp>
                            {/*                         <Grid item xs={12}>
                              <div style={{ fontStyle: "italic", fontSize: 12 }}>
                                {row.ramo}
                              </div>
                            </Grid> */}

                            {/*                           <Grid item xs={12}>
                              <Typography variant="body2" component="p">
                                <span style={{ fontWeight: "bold" }}>
                                  Ofertas:{" "}
                                </span>
                                <span style={{ fontStyle: "italic" }}>
                                  {row.ofertas}
                                </span>
                              </Typography>
                            </Grid>
  
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                component="p"
                                gutterBottom
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Demandas:{" "}
                                </span>
                                <span style={{ fontStyle: "italic" }}>
                                  {row.demandas}
                                </span>
                              </Typography>
                            </Grid> */}
                          </Hidden>
                          {/*     <Hidden xsDown>
                                                     <Grid item>
                              <CardMedia
                                className={classes.bandera}
                                // image={`${row.logo}`}
                                component="img"
                                src={`https://flagcdn.com/${row.codpais}.svg`}
                                // width="24"
                                title={row.pais}
                                style={{ width: 30, marginRight: 20 }}
                              />
                            </Grid> 
  
                            <Grid item>
                              <div style={{ fontStyle: "italic", fontSize: 12 }}>
                                {row.ramo}
                              </div>
                            </Grid>
                     </Hidden> */}
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container>
                                                   <Grid item xs={6}>
                            <Typography
                              noWrap
                              variant="body2"
                              component="p"
                              style={{ fontSize: 12 }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                Ofertas:{" "}
                              </span>
                              <span style={{ fontStyle: "italic" }}>
                                {row.ofertas}
                              </span>
                            </Typography>
                          </Grid> 

                                                   <Grid item xs={6}>
                            <Typography
                              noWrap
                              variant="body2"
                              component="p"
                              style={{ fontSize: 12 }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                Demandas:{" "}
                              </span>
                              <span style={{ fontStyle: "italic" }}>
                                {row.demandas}
                              </span>
                            </Typography>
                          </Grid> 
                          {/*                         <Grid item xs={12} container justify="flex-end">
                            <ModalReunirmeRonda
                              row={row}
                              docRonda={docRonda}
                              fechas={fechas}
                            />
                          </Grid> */}
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  row.habilitado ? row.habilitado : false
                                }
                                onChange={handleChangeParticipanteHabilitado}
                                name={row.uid}
                                color="primary"
                              />
                            }
                            label="Habilitado"
                          />
                        </Grid>
                      </Grid>

                      <FormControlLabel
                        control={
                          <Switch
                            checked={row.expositor ? row.expositor : false}
                            onChange={handleChangeExpositorSwitch}
                            name={row.uid}
                            color="primary"
                          />
                        }
                        label="Expositor"
                      />
                      <button
                        type="button"
                        onClick={() => setOpenBorrarParticipante(true)}
                      >
                        <DeleteForeverIcon fontSize="small" />
                      </button>
                      <Modal
                        open={openBorrarParticipante}
                        onClose={() => setOpenBorrarParticipante(false)}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                      >
                        <div className={classes.paper}>
                          <h2 id="simple-modal-title">Eliminar participante</h2>
                          <p id="simple-modal-description">
                            Esta seguro de borrar este participante? esta acción
                            no es reversible.
                          </p>

                          <button
                            type="button"
                            onClick={() => borrarParticipante(row.uid)}
                          >
                            <DeleteForeverIcon fontSize="small" /> Borrar
                          </button>
                        </div>
                      </Modal>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
        <div
          style={{
            width: "19%",
            position: "absolute",
            // height: "100%",
            top: "1%",
            right: 0,
            padding: "2%",
            backgroundColor: "#fff",
          }}
        >
          <Grid container>
            <Grid item>
              <TextField
                id="search"
                variant="outlined"
                onChange={e => setSearch(e.currentTarget.value)}
                value={search}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={empresaBusqueda}
                    onChange={handleChangeEmpresaBusqueda}
                    name="empresabusqueda"
                    color="primary"
                  />
                }
                label="Empresa"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={paisBusqueda}
                    onChange={handleChangePaisBusqueda}
                    name="paisbusqueda"
                    color="primary"
                  />
                }
                label="Pais"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ofertasBusqueda}
                    onChange={handleChangeOfertasBusqueda}
                    name="ofertasbusqueda"
                    color="primary"
                  />
                }
                label="Ofertas"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={demandasBusqueda}
                    onChange={handleChangeDemandasBusqueda}
                    name="demandasbusqueda"
                    color="primary"
                  />
                }
                label="Demandas"
              />
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  return (
    <>
      <Layout>
        <div style={{ backgroundColor: "#FFFFFF99" }}>
          <Tabs
            value={botonElegidoAdministracion}
            onChange={handleChangeTabsAdministracion}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Todas" value={"Todas"} />
            <Tab label="Aceptadas" value={"Aceptadas"} />
            <Tab label="Pendientes" value={"Pendientes"} />
            <Tab label="Canceladas" value={"Canceladas"} />
            <Tab label="Crear Reunión" value={"CrearReunion"} />
            <Tab label="Participantes" value={"Participantes"} />
          </Tabs>
        </div>
        <div
          style={{
            position: "absolute",
            height: "90%",
            width: "96%",
            top: "10%",
            //  left: "0%",
            overflow: "auto",
            overflowX: "hidden",
            marginRight: "2%",
            marginLeft: "2%",
          }}
        >
          {botonElegidoAdministracion === "Todas" ? (
            <Todas />
          ) : botonElegidoAdministracion === "Aceptadas" ? (
            <Aceptadas />
          ) : botonElegidoAdministracion === "Pendientes" ? (
            <Pendientes />
          ) : botonElegidoAdministracion === "Canceladas" ? (
            <Canceladas />
          ) : botonElegidoAdministracion === "CrearReunion" ? (
            <CrearReunion />
          ) : botonElegidoAdministracion === "Participantes" ? (
            <Participantes />
          ) : (
            ""
          )}
        </div>
      </Layout>
    </>
  )
}
export default injectIntl(AdministradorDeReuniones)
